.kp-planet-row {
  display: flex;
}
body.light-mode .kp-planet-row {
  border-bottom: 0.1px solid #dadada;
}
body.dark-mode .kp-planet-row {
  border-bottom: 0.1px solid #4e4e4e;
}

.kp-planet {
  width: 3em;
}
.kp-planet-houses {
  width: "fit-content";
}

body.light-mode span.kp-planet {
  background-color: var(--background-light-selected);
}

body.dark-mode span.kp-planet {
  background-color: var(--background-dark-selected);
  color: var(--color-dark);
}
