@font-face {
  font-family: 'Samarkan';
  src: local('Samarkan'), url(./fonts/SamarkanNormal.ttf) format('opentype');
}

html {
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
  font-size: 16px;
  font-family: 'Open Sans';

  --background-light: #eef4f7;
  --background-dark: #1e1f1a;

  --background-dark-selected: #54544c;
  --background-light-selected: #dfdfdf;

  --text-color-normal: #0a244d;

  --color-light: #10171d;
  --color-dark: #eef4f7;
  --color-dark-selected: #dfdfdf;

  --nav-bg-color-dark: #1b2938;

  --chart-line-dark: #999;
  --chart-line-normal: #000;

  --table-tr-alternate-dark: #282828;
  --table-tr-alternate-light: #f6f6f6;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

table > thead > tr > th, .pivoted {
  text-align: left;
}

body.light-mode .pivoted {
  border: .1px solid #bababa;
}

body.dark-mode .pivoted {
  border: .1px solid #6f6d6d;
}

body.light-mode .ui.card .meta,
body.light-mode .ui.cards > .card .meta,
body.light-mode .ui.card > .content > .meta + .description,
body.light-mode .ui.card > .content > .header,
body.light-mode {
  background-color: var(--background-light);
  color: var(--color-light);
  transition: background-color 0.3s ease;
}

body.light-mode .ui.card > .content > * {
  color: var(--color-light);
}

body.dark-mode .ui,
body.dark-mode .ui.card,
body.dark-mode .ui.card:after,
body.dark-mode .ui.card > .extra,
body.dark-mode .ui.card > .extra:after,
body.dark-mode .ui.card:after,
body.dark-mode .ui.card > .content:after,
body.dark-mode .ui.card > .content > * {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

body.light-mode .toggle {
  background-color: var(--background-light);
  color: var(--color-light);
}

body.dark-mode .toggle {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

body.dark-mode .ui,
body.dark-mode .ui.steps .step,
body.dark-mode .ui.menu,
body.dark-mode .ui.menu .item,
body.dark-mode .ui.simple.dropdown .menu,
body.dark-mode .ui.accordion,
body.dark-mode .ui.segment,
body.dark-mode .ui.table,
body.dark-mode .ui.table thead th,
body.dark-mode .ui.label,
body.dark-mode .ui.card .meta,
body.dark-mode .ui.cards > .card .meta,
body.dark-mode .ui.card > .content > .meta + .description,
body.dark-mode .ui.steps .step .description,
body.dark-mode {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

body.dark-mode .ui.content {
  background-color: var(--background-dark);
  color: #ee09;
}

body.dark-mode .ReactTable input[type],
body.dark-mode .ui input[type],
body.dark-mode .ui.form input[type],
body.dark-mode .ui.checkbox label,
body.dark-mode .ui.menu .active.item {
  background-color: var(--background-dark-selected);
  color: var(--color-dark-selected);
}

body.dark-mode .content button {
  color: #ee09;
}

body.dark-mode .ui.menu .ui.dropdown .menu > .item {
  background-color: var(--background-dark);
  color: white !important;
}

.dark-mode-toggle {
  display: flex;
  margin: 0 auto;
  & > svg {
    font-size: 1.2em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease;
    &:last-child {
      color: #666;
    }

    &:focus {
      outline: none;
    }
  }
}

.toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}

input[type='checkbox'].dmcheck {
  width: 40px;
  height: 10px;
  background: #555;
  position: relative;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 30px;
  }

  &:focus-visible {
    outline: solid 2px white;
  }

  & + label {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    left: 2px;
    background: #fff;
    opacity: 0.9;
    background-color: #f6f6f6;
  }
}

.chartNorthSvgRect1 {
  color: var(--chart-line-normal);
  stroke: var(--chart-line-normal);
  height: 100%;
  width: 100%;
  x: 0%;
  y: 0%;
  fill: none;
  fill-opacity: 1;
  stroke-width: 3;
}

.line1,
.line2,
.line3,
.line4,
.line5,
.line6 {
  color: var(--chart-line-normal);
  stroke: var(--chart-line-normal);
  stroke-width: 1;
}

.ghaarText {
  fill: var(--chart-line-normal);
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  text-align: start;
  text-anchor: start;
  fill-opacity: 1;
  stroke: none;
  stroke-width: 1px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  /*font-family: Source Sans Pro Extra-Light;*/
}

body.dark-mode .chartNorthSvgRect1 {
  color: var(--chart-line-dark);
  stroke: var(--chart-line-dark);
}

body.dark-mode .line1,
body.dark-mode .line2,
body.dark-mode .line3,
body.dark-mode .line4,
body.dark-mode .line5,
body.dark-mode .line6 {
  color: var(--chart-line-dark);
  stroke: var(--chart-line-dark);
}

body.dark-mode .ghaarText {
  fill: var(--chart-line-dark);
}

.content {
  max-height: 90%;
  overflow: auto;
}

.ui.card > .content > .meta,
.ui.card > .content > .extra.content,
.ui.card > .content > .description {
  /*max-height: 7em;*/
  overflow: auto;
  resize: vertical;
  /* height: calc(20%); */
}

body.dark-mode .content:hover {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

body.light-mode .content:hover {
  background-color: var(--background-light);
  color: var(--color-light);
}

.tag {
  float: left;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 1rem;
  margin: 0 7px 7px 0;
  list-style-type: none;
}

.hoverTag {
  background-color: var(--background-light);
  color: var(--color-light);
  text-decoration: underline;
}

body.light-mode .tag {
  background-color: var(--background-light-selected);
  color: var(--color-light);
}

body.light-mode .hoverTag {
  background-color: var(--background-light);
  color: var(--color-light);
}

body.dark-mode .tag {
  color: var(--color-dark);
  background-color: #615e5e;
}

body.dark-mode .hoverTag {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

.ui .accordion {
  padding: 0.5em 0 0.5em 0;
}

.ui .accordion .title {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.01em;
}

.ui .accordion .content {
  resize: vertical;
  height: calc(20%);
  /*max-height: 7em;*/
  overflow: auto;
}

body.dark-mode .ui .accordion .title {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

body.light-mode .ui .accordion .title {
  background-color: var(--background-light);
  color: var(--color-light);
}

.ui.attached.segment {
  padding: 0;
}

.title .content* {
  padding: 0;
}

.close {
  position: relative !important;
  padding: 0;
  float: right;
}

body.dark-mode .close {
  background-color: var(--background-dark);
  color: var(--color-dark);
}

body.light-mode .close {
  background-color: var(--background-light);
  color: var(--color-light);
}

body.dark-mode tr:nth-child(odd) {
  background-color: var(--table-tr-alternate-dark);
}

body.light-mode tr:nth-child(odd) {
  background-color: var(--table-tr-alternate-light);
}

body.dark-mode textarea {
  background-color: var(--background-dark-selected);
  color: var(--color-dark);
}

body.light-mode textarea {
  background-color: var(--background-light-selected);
  color: var(--color-light);
}

body.dark-mode .clndr-calendars-wrapper {
  background-color: var(--background-dark-selected);
  color: var(--color-dark);
}

body.light-mode .clndr-calendars-wrapper {
  background-color: var(--background-light-selected);
  color: var(--color-light);
}

body.dark-mode .clndr-button-today {
  background-color: var(--background-dark-selected);
  color: var(--color-dark);
}

body.light-mode .clndr-button-today {
  background-color: var(--background-light-selected);
  color: var(--color-light);
}

body.dark-mode .clndr-cell {
  background-color: var(--background-dark-selected);
  color: var(--color-dark);
}

body.light-mode .clndr-cell {
  background-color: var(--background-light-selected);
  color: var(--color-light);
}

body.dark-mode .ui.top.attached.label,
body.dark-mode .ui.bottom.attached.label {
  background-color: var(--background-dark-selected);
  color: var(--color-dark);
}

body.light-mode .lo-shu-label {
  font-size: 0.9rem;
  margin-top: 1rem !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ui.top.attached.label,
.ui.top.attached.label + [class*='right floated'] + *,
.ui.top.attached.label:first-child + :not(.attached) {
  margin-top: 0rem !important;
  margin-bottom: 2rem !important;
  font-size: 1rem;
}

body.dark-mode .lo-shu-label {
  font-size: 0.9rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: var(--background-dark-selected);
}
